import { initialState } from '$/config'
import type { EndStopPreference, Stop } from '$/types'

interface RouteData {
  confirmed: boolean
  currentStep: number
  driver: string
  endStopPreference: EndStopPreference
  lastEdited: number
  notified: boolean
  plan: null
  routeDefaultTimeAtStop: number
  skippedOptimization: boolean
  state: {
    completed: boolean
    completedAt: number
    optimized: boolean
    optimizedAt: number
    started: boolean
    startedAt: number
  }
  createdAt?: number
  startsAt?: number
  title: string
}

export const getDefaultRouteData = (userId: string): RouteData => ({
  confirmed: false,
  currentStep: 1,
  driver: `/users/${userId}`,
  endStopPreference: initialState.endStop.preference,
  lastEdited: Math.floor(Date.now() / 1000),
  notified: false,
  plan: null,
  routeDefaultTimeAtStop: -1,
  skippedOptimization: false,
  state: {
    completed: false,
    completedAt: -1,
    optimized: false,
    optimizedAt: -1,
    started: false,
    startedAt: -1,
  },
  createdAt: Math.floor(Date.now() / 1000),
  startsAt: Math.floor(new Date().setHours(0, 0, 0, 0) / 1000),
  title: 'Route generated from Speedy Route',
})

export const getDefaultStopData = (): Stop => ({
  addedTime: Math.floor(Date.now() / 1000),
  addressLineOne: '',
  addressLineTwo: '',
  arrivalTime: -1,
  deliveryInfo: {
    attempted: false,
    attemptedAt: -1,
    attemptedLocation: null,
    driverProvidedInternalNotes: null,
    driverProvidedRecipientNotes: null,
    photoUrl: null,
    signatureUrl: null,
    signeeName: null,
    state: 'unattempted',
    succeeded: false,
  },
  departureTime: -1,
  distanceMeters: -1,
  done: false,
  doneTime: -1,
  driver: '',
  durationSeconds: -1,
  estimatedTimeAtStop: -1,
  formattedAddress: '',
  id: '',
  isGeocoded: true,
  localData: {
    driverNumber: -1,
    stopNumber: -1,
  },
  latitude: -1,
  longitude: -1,
  nextStopClickedTime: 0,
  nextStopTime: Number.MAX_SAFE_INTEGER,
  notes: '',
  optimized: false,
  placeId: '',
  placeTypes: [],
  polyLineString: null,
  previouslyDone: false,
  priority: 1,
  searchToken: '',
  skippedReason: -1,
  timeWindowEarliestTime: -1,
  timeWindowLatestTime: -1,
  tracked: false,
  type: 'stop',
})
