import type { AppState, Plans, Subscription } from '$/types'

import type { FlatfileSettings } from '@flatfile/react'

const serviceHosts: Record<string, any> = {
  emulated: {
    functions: 'http://localhost:5000/circuit-dev-e0970/us-central1',
    firestore: 'http://localhost:5001',
    auth: 'http://localhost:9099',
    engine: 'http://localhost:3333/rest',
  },
  development: {
    // Released on every PR & merge to main (deploys override each other)
    functions: 'https://us-central1-circuit-dev-e0970.cloudfunctions.net',

    // Auto-deploys latest `main` branch state
    engine: 'https://staging-api.getcircuit.com/rest',
  },
  production: {
    // Released on every PR & merge to main (deploys override each other)
    functions: 'https://us-central1-circuit-prod.cloudfunctions.net',

    // Auto-deploys latest `main` branch state
    engine: 'https://api.getcircuit.com/rest',
  },
}

export const useEmulator = process.env.FIREBASE_EMULATOR
export const env = process.env.NODE_ENV

const getHostForService = (serviceName: string): string | undefined => {
  if (useEmulator) return serviceHosts.emulated[serviceName]
  const hostsForEnv = serviceHosts[env] || serviceHosts.development

  return hostsForEnv[serviceName]
}

export const isBrowser = typeof window !== 'undefined'
export const isDev = env === 'development'
export const { testLocale } = process.env

export const firebaseFunctionsHost = getHostForService('functions')
export const firebaseFirestoreHost = getHostForService('firestore')
export const firebaseAuthHost = getHostForService('auth')
export const circuitEngineHost = getHostForService('engine')

// isDev
//   ? 'https://latest---api-yr6hrebuxq-ew.a.run.app/rest/'
//   : 'https://api.getcircuit.com/rest/'
export const apiBaseUrl = circuitEngineHost

// isDev
//   ? 'https://us-central1-circuit-dev-e0970.cloudfunctions.net/'
//   : 'https://us-central1-circuit-prod.cloudfunctions.net/'
export const backendBaseUrl = firebaseFunctionsHost

export const flatfileButtonSettings: FlatfileSettings = {
  managed: true,
  type: 'Addresses',
  fields: [
    {
      label: 'Address Line 1',
      validators: [{ validate: 'required' }],
      key: 'addressLine1',
      alternates: ['address line one'],
    },
    {
      label: 'Address Line 2',
      key: 'addressLine2',
      alternates: ['address line two'],
    },
    { label: 'City', key: 'city', alternates: ['town'] },
    { label: 'State', key: 'state', alternates: ['county'] },
    { label: 'Zip', key: 'zip', alternates: ['postcode'] },
    { label: 'Country', key: 'country' },
    { label: 'Notes', key: 'notes' },
  ],
}

export const amplitudeKey = '58bd44800674e228332ba738b7680b5a'
export const flatfileLicenseKey = '82110e30-78c2-11e9-b577-23c2904d0f8b'
export const googleMapsApiKey =
  env === 'production'
    ? 'AIzaSyD_V2D6Sp3glWtiIztB096tqyc2_kkls_g'
    : 'AIzaSyAMIvAtzePLTHGhgTHvyEhLTRKXeA9Pw-o'

// Not counting startStop and endStop
export const initialNumberOfStopInputs = 2

export const featuresForUsersWhoAreNotSubscribed: {
  [key: string]: Subscription
} = {
  anonymous: {
    validUntil: -1,
    stripeCustomerId: '',
    stripeProductIds: new Map(),
    maxDrivers: 1,
    maxStops: 10,
    teamId: '',
  },

  unsubscribed: {
    validUntil: -1,
    stripeCustomerId: '',
    stripeProductIds: new Map(),
    maxDrivers: 1,
    maxStops: 10,
    teamId: '',
  },
}

export const initialState: AppState = {
  initialized: false,
  maximumNumberOfVehicles: 1,
  stops: new Map(),

  endStop: { preference: 'none' },
  mapCenter: { latitude: 51.5074, longitude: 0.1278 },
  openDialog: 'none',
  user: null,
  subscription: featuresForUsersWhoAreNotSubscribed.anonymous,
}

export const latestGitCommitHash = process.env.NEXT_PUBLIC_GIT_SHA

export const maximumAllowedNumberOfVehicles = 10

export const autoAnonymousLoginDelay = 3000

export const search = {
  shortDebounceDelay: 300,
  longDebounceDelay: 1000,
}

export const note = {
  maxChars: 256,
  millisecondsBeforeSave: 2000,
}

export const maximumStopsInARoute = 999

export const plans: Plans = {
  singleDriver: {
    active: false,
    id: isDev ? 'prod_IC9uyCHW1r3VcW' : 'prod_IC9nwCg6exexA2',
    level: 1,
    title: 'Single driver',
    description:
      'Create routes for a single driver with up to 100 stops per route',
    price: 39,
    features: {
      maxDrivers: 1,
      maxStops: 100,
    },
    stripePriceId: isDev
      ? 'price_1HblavDjIqUkOixH4ZoBMrJ6'
      : 'price_1HblTvDjIqUkOixHOrrTBMw0',
    stripeProductId: isDev ? 'prod_IC9uyCHW1r3VcW' : 'prod_IC9nwCg6exexA2',
  },

  smallTeam: {
    active: true,
    id: isDev ? 'prod_IC9uoFiVUlrCjt' : 'prod_IC9ntqWEH0XgFC',
    title: 'Small team',
    level: 2,
    description:
      'Create routes for up to 5 drivers with up to 500 stops per route',
    price: 69,
    features: {
      maxDrivers: 5,
      maxStops: 500,
    },
    stripePriceId: isDev
      ? 'price_1Hblb6DjIqUkOixHXKdWoQRh'
      : 'price_1HblUIDjIqUkOixHhLnAgS9k',
    stripeProductId: isDev ? 'prod_IC9uoFiVUlrCjt' : 'prod_IC9ntqWEH0XgFC',
  },

  largeTeam: {
    active: true,
    id: isDev ? 'prod_IC9vks8T8dQejI' : 'prod_IC9oKg64N5IYbu',
    title: 'Large team',
    level: 3,
    description:
      'Create routes for up to 10 drivers with up to 999 stops per route',
    price: 99,
    features: {
      maxDrivers: 10,
      maxStops: 999,
    },
    stripePriceId: isDev
      ? 'price_1HblbFDjIqUkOixHBPEoX1A7'
      : 'price_1HblUSDjIqUkOixHwn1rvKMw',
    stripeProductId: isDev ? 'prod_IC9vks8T8dQejI' : 'prod_IC9oKg64N5IYbu',
  },
}

export const driverColors = [
  '#476FF1',
  '#F24242',
  '#43D072',
  '#8E6AF3',
  '#40B2F9',
  '#E59900',
  '#6CD025',
  '#D86EE2',
  '#F5647C',
  '#BBCC00',
  '#3F90FA',
  '#F46A25',
  '#D6BA00',
  '#4BD2BC',
  '#6C6AF7',
  '#F060C7',
  '#8EBD00',
  '#41C7DE',
  '#F871A7',
  '#BE73EA',
]

export const googleAnalyticsTrackingId = 'UA-83363830-1'

export const supportEmail = 'speedyroute@circuit.helpscoutapp.com'
